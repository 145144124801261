import request from '../utils/request';

/**
 * 竞品企业
 * @param query
 * @returns {AxiosPromise}
 */
export const pageList = (param) => {
    return request({
        url: 'web/opponent/pageList',
        method: 'GET',
        params: param
    });
};
/**
 * 导出
 * @param query
 * @returns {AxiosPromise}
 */
export const excel = (query) => {
    return request({
        url: 'web/opponent/excel',
        method: 'GET',
        params: query,
        responseType: 'arraybuffer'
    });
};

export const addCompete = (name,regionId) => {
    return request({
        url: 'web/opponent/addCompete',
        method: 'POST',
        params: {name,regionId},
    });
};
export const selectOpponentType = (regionId) => {
    return request({
        url: 'web/opponent/selectOpponentType',
        method: 'GET',
        params: {regionId},
    });
};