<template>
  <div >
    <!--条件搜索-->
    <div class="handle-box">
      <el-button type="primary" class="mr10" @click="addVisible = true">添加企业</el-button>
      <el-select v-model="param.regionId" placeholder="城市" class="handle-select mr10">
        <el-option @click="selectCity1(index,item.id)" v-for="(item,index) in regionList" :key="index"
                   :label="item.region" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="param.campusId" placeholder="校区" style="width: 160px" class="handle-select mr10">
        <el-option style="width: 160px" v-for="(item,index) in campusList" :key="index"
                   :label="item.campusname" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="param.competeId" placeholder="类型" class="handle-select mr10">
        <el-option v-for="(item,index) in CompeteList" :key="index"
                   :label="item.dictLabel" :value="item.dictValue"></el-option>
      </el-select>
      <el-date-picker style="width: 260px" v-model="v1" type="daterange" range-separator="至"
                      start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-input v-model="param.coachName" clearable placeholder="教练名称" class="handle-select mr10"></el-input>
      <el-button type="primary" icon="Search" style="margin-left: 10px" @click="handleSearch">搜索
      </el-button>
      <el-button type="info" @click="reset">重置</el-button>

      <el-popconfirm @confirm="exportExcel"
                     title="确定要导出吗？"
      >
        <template #reference>
          <el-button type="primary" icon="Download">导出</el-button>
        </template>
      </el-popconfirm>
    </div>

    <!--table展示数据-->
    <el-table
        height="655px"
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        :row-style="{height:15+'px'}"
        :cell-style="{padding:10+'px'}"
    >
      <el-table-column
          width="100"
          show-overflow-tooltip="true"
          prop="regionName"
          label="城市名称">
      </el-table-column>
      <el-table-column
          width="150"
          show-overflow-tooltip="true"
          prop="campusName"
          label="校区">
      </el-table-column>
      <el-table-column
          width="100"
          show-overflow-tooltip="true"
          prop="submitName"
          label="提交人">
      </el-table-column>
      <el-table-column
          width="170"
          show-overflow-tooltip="true"
          prop="trainingTime"
          label="训练时间"
      ></el-table-column>
      <el-table-column width="140" label="竞品名称">
        <template #default="scope">

          <el-popover
              placement="top-start"
              :width="350"
              trigger="hover">
            <div v-for="item in scope.row.opponent" :key="item.id">
              <div style="margin: 6px 0; display: flex">
                <div style="width: 150px">
                  企业：{{ item.competeName }}
                </div>
                <el-divider direction="vertical"></el-divider>
                <div style="width: 80px">
                  老师：{{ item.teacherNum }}
                </div>
                <el-divider direction="vertical"></el-divider>
                <div style="width: 80px">
                  学员：{{ item.studentNum }}
                </div>
              </div>
            </div>
            <template #reference>
              <el-button>查看竞品</el-button>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          width="155"
          show-overflow-tooltip="true"
          prop="competeSize"
          label="企业个数">
      </el-table-column>
      <el-table-column
          width="155"
          show-overflow-tooltip="true"
          prop="teacherTotalNum"
          label="老师数量">
      </el-table-column>
      <el-table-column
          width="155"
          show-overflow-tooltip="true"
          prop="studentTotalNum"
          label="学生数量">
      </el-table-column>
      <el-table-column
          width="170"
          show-overflow-tooltip="true"
          prop="createTime"
          label="创建时间"
      ></el-table-column>
    </el-table>
    <!--分页-->
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="param.page"
          :page-size="param.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <!-- 新增   -->
    <!--    <el-dialog title="添加企业" v-model="addModule" width="600px">
          <el-form style="margin-right: 0px" :model="insertForm" :rules="rules" ref="insertForm" label-width="auto"
                   class="demo-ruleForm">
            <el-row>
              <el-col :span="10">
                <el-form-item class="itemWih" label="企业名称" prop="name">
                  <el-input v-model="insertForm.name" placeholder="请输入企业名称"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="addModule=false">取 消</el-button>
              <el-button type="primary" @click="insertActivity(this.insertActivity.name)">确 定</el-button>
            </span>
          </template>
        </el-dialog>-->
    <!-- 新增弹出框框 -->
    <el-dialog title="企业信息录入" v-model="addVisible" width="30%">
      <el-form :rules="rules" :model="insertForm" label-width="90px">
        <el-form-item prop="name" label="企业名称">
          <el-input v-model="insertForm.name"></el-input>
        </el-form-item>

        <el-form-item prop="regionId" label="城市">
          <el-select v-model="insertForm.regionId" placeholder="城市" class="handle-select mr10">
            <el-option v-for="(item,index) in regionList" :key="index" :label="item.region"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary"
                               @click="insertActivity(insertActivity.name,insertActivity.regionId)">确 定</el-button>
                </span>
      </template>
    </el-dialog>

  </div>

</template>

<script>

// import {cityList} from "@/api/public";
import {pageList, excel, addCompete, selectOpponentType} from "../../api/competeEnterprise";

export default {
  name: "CompeteEnterprise",
  data() {
    return {
      tableData: [],
      pageTotal: null,
      startDateAndEndDate: null,
      v1: [],
      regionList: [],
      addVisible: false,//添加活动弹出层
      campusList: [],
      insertForm: {},
      CompeteList: [],
      campusList1: [],
      form: {},
      opponent: [],
      param: {
        page: 1,
        pageSize: 10,
        startDate: '',
        endDate: '',
        regionId: '',
        campusId: '',
        competeId: '',
        coachName: ''
      },
      rules: {
        name: [{required: true, message: '请输入城市', trigger: 'blur',},],
        regionId: [{required: true, message: '请选择城市', trigger: 'blur',},],
      },


    }
  },
  methods: {
    getData() {
      pageList(this.param).then(val => {
        console.log(val)
        if (val.code === 200) {
          this.tableData = val.data.records;
          this.pageTotal = val.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
        console.log(val)
      })
    },
    // 触发搜索按钮
    handleSearch() {
      if (this.v1.length >= 2) {
        this.param.startDate = this.dateFormat(this.v1[0]);
        this.param.endDate = this.dateFormat(this.v1[1]);
      }
      this.param.page = 1
      this.getData();
    },
    /**
     * 切换页面
     */
    handlePageChange(val) {
      this.param.page = val;
      this.getData();
    },
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },
    /*选中城市  渲染校区*/
    selectCity1(val, regionId) {
      console.log("val" + val)
      selectOpponentType(regionId).then(res => {
        if (res.code === 200) {
          this.CompeteList = res.data
          console.log("this.CompeteList" + this.CompeteList)
        } else {
          this.$message.error("获取数据失败");
        }
      })
      this.param.campusId = null
      if (JSON.stringify(this.regionList[val])) {
        this.campusList = JSON.parse(JSON.stringify(this.regionList[val])).campuses
      }
    },
    /**
     * 添加
     */
    async insertActivity() {
      console.log("this.insertForm.name" + this.insertForm.name)
      console.log("this.insertForm.regionId" + this.insertForm.regionId)
      if (this.insertForm.name === undefined || this.insertForm.regionId === undefined) {
        this.$message.error('添加失败！');
        this.addVisible = false;
      } else {
        addCompete(this.insertForm.name, this.insertForm.regionId).then(val => {
          if (val.code === 200) {
            this.$message.success(val.message);
            this.addVisible = false;
          } else {
            this.$message.error(val.message);
          }

        })
      }

    },
    //导出
    exportExcel() {
      this.loading = true
      if (this.startDateAndEndDate) {
        this.param.startDate = this.dateFormat(this.startDateAndEndDate[0])
        this.param.endDate = this.dateFormat(this.startDateAndEndDate[1])
      }
      excel(this.param).then(res => {
        console.log(res)
        this.loading = false
        let blob = new Blob([res], {type: "application/vnd.ms-excel"});
        console.log(blob);
        let objectUrl = URL.createObjectURL(blob);
        console.log(objectUrl);
        const link = document.createElement('a');//我们用模拟q标签点击事件
        const fname = '竞品表'; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute('download', fname);
        document.body.appendChild(link);
        /**
         * 在这期间，我们可以做自己界面的反应逻辑
         **/
        link.click();//点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
      })
    },
    //重置
    reset() {
      this.param.regionId = ""
      this.param.campusId = ""
      this.param.competeId = ""
      this.param.coachName = ""
      // 日期
      this.v1 = []
      this.param.startDate = ""
      this.param.endDate = ""
      this.param.page = 1
      this.param.pageSize = 10
      this.CompeteList = null
      this.getData()
    },

  },
  /**
   * 加载页面
   */
  created() {
    this.getData();
    // 城市下拉数据
    // cityList().then(res => {
    //   if (res.code == 200) {
    //     this.regionList = res.data;
    //   } else {
    //     this.$message.error("获取城市数据失败");
    //   }
    // })

  },
}
</script>

<style scoped>
.handle-box {
  display: flex;
  margin-bottom: 20px;
}

.handle-select {
  width: 160px;
}

.mr10 {
  margin-right: 10px;
}

.code {
  margin-top: 8px;
  width: 80px;
  height: 40px;
  border-radius: 6px;
  background-color: #efefef;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}

/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .avatar {
  width: 80px;
  height: 80px;
}
</style>